const ImageBanner = ({ product }) => {
    return (
        <a href={product?.url}>
            <img
                className="toplist-banner"
                src={product.image}
                width={256}
                alt={product?.name}
            />
        </a>
    );
};

export default ImageBanner;
