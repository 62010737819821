import PropTypes from 'prop-types';
import React from 'react';
import logo from '../../assets/images/huppa_logo.svg';
import Footer from '../Footer/Footer';
import { Col, Container, Dropdown, DropdownToggle, Row } from 'mdbreact';
import {
    homeGrey,
    homeWhite,
    deliveryWhite,
    deliveryGrey,
    allergensGrey,
    allergensWhite,
    campaignsGrey,
    campaignsWhite,
    packagingGrey,
    packagingWhite,
    recipesGrey,
    recipesWhite,
    downloadGrey,
    downloadWhite,
    promotionsWhite,
    promotionsGrey,
    pageWhite,
    pageGrey,
    tvIconWhite,
    tvIconGrey,
    newReleaseWhite,
    newReleaseGrey,
    exhibitionModuleGrey,
    exhibitionModuleWhite,
} from '../../assets/images/icons';
import AccountDropdownMenu from '../AccountDropdownMenu';
import SectionButtonContainer from './components/SectionButton/SectionButtonContainer';
import NotificationContainer from './components/Notification/NotificationContainer';
import PromotionalModalContainer from '../PromotionalModal/PromotionalModalContainer';
import Spinner from '../Utils/Spinner';
import CustomersContainer from '../Customers/CustomersContainer';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const Dashboard = ({
    logout,
    userDetails,
    notifications,
    locale,
    isCampaignActive,
}) => {
    const isFairModule = userDetails?.show_only_fair_module;

    return (
        <div className="dashboard container-fluid">
            <Row>
                <Col>
                    <img className="dashboard__logo" src={logo} alt="logo" />
                </Col>
            </Row>
            <Row className="justify-content-md-center dashboard__dropdowns__container">
                <div className="dashboard__dropdowns">
                    <CustomersContainer
                        userDetails={userDetails}
                        className="dashboard__dropdowns__customer"
                    />
                    <Dropdown className="dashboard__account-switch account-dropdown-menu">
                        <DropdownToggle nav caret>
                            <span className="dashboard__account-switch__label">
                                <FormattedMessage id="account.label" />{' '}
                                <span
                                    style={{
                                        fontSize: '24px',
                                        lineHeight: '10px',
                                    }}
                                >
                                    |
                                </span>{' '}
                                <span className="dashboard__account-switch__label__account">
                                    {!userDetails ? (
                                        <Spinner
                                            width={15}
                                            height={15}
                                            className="spinner--white"
                                        />
                                    ) : (
                                        userDetails.username
                                    )}
                                </span>
                            </span>
                        </DropdownToggle>
                        {userDetails && (
                            <AccountDropdownMenu
                                logout={logout}
                                showOnlyFairModule={
                                    userDetails?.show_only_fair_module
                                }
                                accountList={[
                                    {
                                        id: userDetails.id,
                                        username: userDetails.username,
                                    },
                                ]}
                                selected={userDetails.id}
                            />
                        )}
                    </Dropdown>
                </div>
            </Row>
            {notifications && (
                <Container className="container-smaller dashboard__notifications-container">
                    {notifications.map((notification) => {
                        return (
                            <NotificationContainer
                                key={notification.id}
                                id={notification.id}
                                text={notification.notification}
                                url={notification.link}
                            />
                        );
                    })}
                </Container>
            )}
            <div className="justify-content-md-center dashboard__sections">
                <Container className="container-smaller">
                    <Row>
                        <Col
                            className={classNames(
                                'dashboard__section__wrapper',
                                {
                                    dashboard__fair__section__wrapper:
                                        isFairModule,
                                }
                            )}
                        >
                            {userDetails && isFairModule && (
                                <SectionButtonContainer
                                    to="/fair"
                                    label="dashboard.section.exhibition_module"
                                    icon={exhibitionModuleWhite}
                                    iconHover={exhibitionModuleWhite}
                                />
                            )}
                            {userDetails && !isFairModule && (
                                <>
                                    <SectionButtonContainer
                                        to="/products"
                                        label="dashboard.section.order"
                                        icon={homeWhite}
                                        iconHover={homeWhite}
                                    />
                                    <SectionButtonContainer
                                        to="/deliveries"
                                        label="dashboard.section.delivers"
                                        icon={deliveryWhite}
                                        iconHover={deliveryWhite}
                                    />
                                    <SectionButtonContainer
                                        to="/promotions"
                                        label="dashboard.section.promotions"
                                        icon={promotionsWhite}
                                        iconHover={promotionsWhite}
                                    />
                                    <SectionButtonContainer
                                        to="/novelty"
                                        label="dashboard.section.novelty"
                                        icon={newReleaseWhite}
                                        iconHover={newReleaseWhite}
                                    />
                                    <SectionButtonContainer
                                        to={
                                            locale === 'nl'
                                                ? 'https://huppa.be/category/folders/'
                                                : 'https://huppa.be/fr/category/brochures/'
                                        }
                                        isExternal
                                        label="dashboard.section.folders"
                                        icon={pageWhite}
                                        iconHover={pageWhite}
                                    />
                                    {/*<SectionButtonContainer
                                to="/downloads"
                                label="dashboard.section.downloads"
                                icon={downloadWhite}
                                iconHover={downloadGrey}
                            />*/}
                                    <SectionButtonContainer
                                        to="/allergens"
                                        label="dashboard.section.allergens"
                                        icon={allergensWhite}
                                        iconHover={allergensWhite}
                                    />
                                    <SectionButtonContainer
                                        to="/recipes/categories"
                                        label="dashboard.section.recipes"
                                        icon={recipesWhite}
                                        iconHover={recipesWhite}
                                    />
                                    <SectionButtonContainer
                                        to="/campaign"
                                        label="dashboard.section.campaigns"
                                        icon={campaignsWhite}
                                        iconHover={campaignsWhite}
                                        isDisabled={!isCampaignActive}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer inverted />

            {!userDetails?.show_only_fair_module && (
                <PromotionalModalContainer />
            )}
        </div>
    );
};

Dashboard.propTypes = {
    isCampaignActive: PropTypes.bool,
    locale: PropTypes.string,
    logout: PropTypes.func,
    notifications: PropTypes.array,
    userDetails: PropTypes.object,
};

export default Dashboard;
